<template>
    <a-card
        :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}"
    >
        <template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Products</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-input-search v-model='q' @search="onSearch">
					</a-input-search>
                </a-col>
			</a-row>
		</template>
        <a-checkbox-group @change="productSelected" style="display:inline">
        <a-table :columns="productcolumns" :data-source="products" :pagination="{position: 'bottom',pageSize:8}">
            <template slot="selectorColumn" slot-scope="row">
				<a-checkbox :value="row"></a-checkbox>
			</template>
        </a-table>
        </a-checkbox-group>
        <a-button shape="round" @click="handleSelect" type="primary" style="margin-left:10px">OK</a-button>
        <a-button shape="round" @click="cancel" type="secondary" style="margin-left:10px">Cancel</a-button>

    </a-card>
</template>
<script>
import {list} from '@/api/product';
import {modifyproducts} from '@/api/category';
import {search} from "@/api/product";

export default ({
    data() {
        return {
            q:'',
            products: [],
            selectedProducts:[],
            productcolumns:[
                {
                    title:"", 
                    dataIndex:'productKey',
                    scopedSlots: { customRender: 'selectorColumn' },
                    width: 50,
                },
                {title:"Name", dataIndex:'name'},
                {title:'Unique Id', dataIndex:'uniqueid'}
            ]
        };        
    },
    props:{
        categoryid:String
    },
    mounted(){
        // function: category-20
        list().then(data=>{
            this.products = data;
        });
    },
    methods:{
        productSelected(values){
            this.selectedProducts = values;
        },
        cancel(){
            this.$router.go(-1);
        },
         // function: category-21
        handleSelect(){
            modifyproducts({categoryid:this.categoryid,products:this.selectedProducts}).then(data=>{
                this.$router.go(-1);
            })
        },
        // function: category-22
        onSearch(v){
            search({q:v}).then(response=>{
                this.products=response;
            });
        }
    }
})
</script>
